import * as React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import Fade from "react-reveal/Fade";

import PageWrapper from "../components/PageWrapper";
import Container from "../components/Container";
import NextProject from "../components/NextProject";
import globe from "../images/globe.svg";
import TransitionLink, { TransitionState } from "gatsby-plugin-transition-link";
import gsap from "gsap";

import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

import chunk from "lodash/chunk";
import sample from "lodash/sample";

export default function Project({ pageContext: { project } }) {
	const [loadedWebsite, setLoadedWebsite] = React.useState("");

	React.useEffect(() => {
		enableBodyScroll(document.body);
	}, []);

	// const setRandomWebsite = React.useCallback(async (projects) => {
	// 	console.log(projects);
	// }, []); // update the callback if the state changes

	const setRandomWebsite = (projects) => {
		// console.log("sd");
		setLoadedWebsite(sample(projects));
	};

	const chunkedTechnologies =
		project.technologies.length > 3
			? chunk(project.technologies, 3)
			: project.technologies;

	return (
		<PageWrapper>
			<Container>
				<Fade top>
					<ProjectTitle>
						{project.title}
						<ProjectColor color={project.projectColor}>.</ProjectColor>
					</ProjectTitle>
				</Fade>
				<ProjectData>
					<TechnologiesContainer>
						<Fade left>
							<TechnologiesTitle>Technologies</TechnologiesTitle>
						</Fade>
						<Fade delay={500} left>
							{project.technologies.length > 3 ? (
								<TechnologiesWrapper>
									<TechnologiesColumn>
										{chunkedTechnologies[0].map((technology) => {
											return <Technologies>{technology}</Technologies>;
										})}
									</TechnologiesColumn>
									<TechnologiesColumn>
										{chunkedTechnologies[1].map((technology) => {
											return <Technologies>{technology}</Technologies>;
										})}
									</TechnologiesColumn>
								</TechnologiesWrapper>
							) : (
								<TechnologiesWrapper>
									<TechnologiesColumn>
										{chunkedTechnologies.map((technology) => {
											return <Technologies>{technology}</Technologies>;
										})}
									</TechnologiesColumn>
								</TechnologiesWrapper>
							)}
						</Fade>
					</TechnologiesContainer>
					<Fade delay={100} right>
						<IntroText>{project.first_paragraph}</IntroText>
					</Fade>
				</ProjectData>
				<ResultImageContainer>
					<Fade delay={150} bottom>
						<ResultImage src={project.images.resultImage} />
					</Fade>
				</ResultImageContainer>
				{project.second_paragraph && (
					<Fade delay={200} left>
						<FinalText>{project.second_paragraph}</FinalText>
					</Fade>
				)}
				{project.oneliner && (
					<Fade delay={225} left>
						<FinalText>{project.oneliner}</FinalText>
					</Fade>
				)}
				{project.images.ImpressionImage && (
					<ImpressionImageContainer>
						<Fade delay={250} bottom>
							<ImpressionImage src={project.images.ImpressionImage} />
						</Fade>
					</ImpressionImageContainer>
				)}

				<RedirectContainer>
					{typeof project.redirectLink === "string" ? (
						<CustomRedirectLink href={project.redirectLink} target="_blank">
							Check website <GlobeIcon src={globe} />
						</CustomRedirectLink>
					) : (
						project.redirectLink && (
							<CustomRedirectLink
								onClick={() => setRandomWebsite(project.redirectLink)}
								href={loadedWebsite}
								target="_blank"
							>
								Check out one of the websites <GlobeIcon src={globe} />
							</CustomRedirectLink>
						)
					)}
				</RedirectContainer>

				<RedirectContainer></RedirectContainer>

				{project.nextProject && (
					<NextProject nextProject={project.nextProject} />
				)}
			</Container>
		</PageWrapper>
	);
}

const ProjectTitle = styled.h1`
	font-family: "futura medium";
	font-size: 6rem;
	margin: 0;
	text-align: center;

	-ms-word-break: break-all;
	word-break: break-all;

	/* Non standard for webkit */
	word-break: break-word;

	-webkit-hyphens: auto;
	-moz-hyphens: auto;
	-ms-hyphens: auto;
	hyphens: auto;

	@media (max-width: 768px) {
		font-size: 4.8rem;
	}
`;

const ProjectColor = styled.span`
	color: ${(props) => props.color};
`;

const ProjectData = styled.div`
	display: flex;
	margin-top: 30px;
	align-items: center;

	@media (max-width: 768px) {
		flex-direction: column;
	}
`;

const TechnologiesContainer = styled.div`
	width: 55%;
	padding-right: 40px;
	max-width: 270px;

	@media (max-width: 768px) {
		width: 70%;
		margin: 0 auto;
		padding-right: 0;
	}
`;

const TechnologiesWrapper = styled.div`
	display: flex;
	justify-content: space-between;
`;

const FinalText = styled.p`
	max-width: 720px;
	margin: 0 auto;
	padding-top: 40px;
`;

const TechnologiesColumn = styled.div`
	display: flex;
	flex-direction: column;
`;

const IntroText = styled.div`
	width: 90%;

	@media (max-width: 768px) {
		margin-top: 50px;
		width: 100%;
	}
`;

const TechnologiesTitle = styled.h3`
	color: #839cb2;
	margin-bottom: 10px;
`;

const Technologies = styled.span``;

const ResultImageContainer = styled.div`
	padding-top: 40px;
	display: flex;
	justify-content: center;
`;

const ResultImage = styled.img`
	max-width: 100%;
	height: auto;
	margin: 0 auto;
	max-height: 500px;
`;

const ImpressionImageContainer = styled.div`
	padding-top: 40px;
	display: flex;
	justify-content: center;
`;

const ImpressionImage = styled.img`
	max-width: 100%;
	height: auto;
	margin: 0 auto;
	max-height: 500px;
`;

const RedirectContainer = styled.div`
	max-width: 720px;
	margin: 30px auto;
`;

const CustomRedirectLink = styled.a`
	color: #839cb2;
	display: flex;
	align-items: center;
	cursor: pointer;
	text-decoration: none;
`;

const GlobeIcon = styled.img`
	max-width: 25px;
	margin-left: 10px;
	transition: all 0.7s ease-in-out;

	${CustomRedirectLink}:hover & {
		margin-left: 20px;
		transform: rotate(180deg);
	}
`;
