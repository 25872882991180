import * as React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import TransitionLink from "gatsby-plugin-transition-link";

import arrow from "../../images/arrow.svg";

const NextProject = (props) => {
	return (
		<NextProjectWrapper
			to={`/projects/${props.nextProject}`}
			exit={{
				// length: 1.5,
				appearAfter: 0.2,
				state: { previousProject: `${props.slug}` },
			}}
			entry={{
				appearAfter: 0.2,
				state: { nextProject: `${props.nextProject}` },
				// length: 1.5,
			}}
		>
			<Title>
				Next <br /> project
			</Title>
			<NextArrow src={arrow} />
		</NextProjectWrapper>
	);
};

export default NextProject;

const NextProjectWrapper = styled(TransitionLink)`
	display: flex;
	flex-direction: row;
	max-width: 500px;
	margin: 0 auto;
	text-decoration: none;

	&:hover {
		cursor: pointer;
	}

	@media (max-width: 768px) {
		flex-direction: column;
		align-items: center;
	}
`;

const Title = styled.h3`
	font-size: 4rem;
	padding-right: 100px;
	color: #839cb2;

	@media (max-width: 768px) {
		padding-right: 0;
		margin-bottom: 0;
	}
`;

const NextArrow = styled.img`
	color: #fff;
	max-width: 200px;
	transition: ease 0.5s;
	position: relative;
	left: 0;

	${NextProjectWrapper}:hover & {
		left: 50px;
	}
`;
